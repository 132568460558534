
@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

}     */

.btn-black{
  @apply bg-black-2 text-white px-6 py-2 hover:bg-black-1/90 focus:outline-black-1/5 focus:outline rounded-md text-sm font-normal;
}

.border-line{
  @apply border rounded-md bg-white;
}
/* .input{
  @apply bg-gray-50 border placeholder:text-black-3 focus:outline focus:outline-2 focus:outline-gray-600/20;
} */

.input{
  @apply bg-[#F5F5F5] mt-1 p-2 w-full border  rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300/50 transition-colors duration-300;
}

.input-textarea{
  @apply h-[120px] bg-[#F5F5F5] mt-1 p-2 w-full border  rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300;
}

.input-form{
  @apply mb-4;
}
.input-form label{
  @apply mb-1;
}
h1, h2, h3, h4, h5{
  @apply text-[#050F24] font-medium text-lg;
}

p{
  @apply text-sm text-black-3
}

label{
  @apply block text-sm font-medium text-gray-700 text-start;
}
th{
  @apply text-black-1 items-start font-medium;
}
td{
  @apply text-black-3 font-normal px-6 py-4;
}
.info tr td:first-child{
  @apply font-medium text-black-1 text-sm px-4 py-2 text-start w-1/2 ;
}
.info tr td:last-child{
  @apply font-normal text-black-3 text-sm  px-4 py-2 text-start  w-1/2 ;
}
.info tr{
  @apply hover:bg-white;
}
.tableDetail{
  @apply w-full text-sm font-light dark:text-white mx-10 my-6;
}
.tableDetail thead th, .tableDetail tbody tr td{
  @apply text-start px-4 ;
}
.tableDetail tr td{
  @apply w-[25%];
}
.tableDetail tr {
  @apply hover:bg-white;
}

.rapport span{
  @apply text-black-1 font-bold underline;
}
nav ul li {
  @apply font-medium text-black-3;
}
.btn-black-outline{
  @apply  border-black-1 border-2 text-black-2  text-sm flex gap-2 items-center px-4 py-2 rounded-md;
}
.status-succes{
  @apply bg-green-600/10 text-green-700 flex gap-3 py-2 px-4 rounded-full w-[120px] items-center;
}

.status-error{
  @apply bg-red-600/10 text-red-500 flex gap-3 py-2 px-4 rounded-full w-[120px] items-center;
}
.status-error div{
  @apply bg-red-500;
}

tr{
  @apply   transition duration-300 ease-in-out hover:bg-neutral-50 dark:border-white/10 dark:hover:bg-neutral-600 cursor-pointer;
}

@font-face {
  font-family: Poppins;
  src: url('./assets/font/poppins.regular.ttf');
}

@font-face {
  font-family: IBMSans;
  src: url('./assets/font/IBMPlexSans-Medium.ttf');
}
.trainer-layout{
  font-family: Poppins;
  font-size: 14px;
}
th , nav , h1, h2, h3, h4, h5{
  font-family: IBMSans;
  font-size: 18px;
  @apply font-medium text-black-1;
}
th {
  font-family: IBMSans;
  font-size: 14px;
  @apply font-medium text-black-1;
  position: 
}

table{
  @apply text-start;
}
table th{
  @apply text-start;

}

.btn{
  @apply h-9 px-8 rounded-[8px] font-semibold;
}
.btn-outline{
  @apply border border-slate-700 text-slate-700 active:bg-slate-200 ;
}
.btn-solid{
  @apply bg-slate-700 text-white;
}
.input {
  @apply border border-[#DDDDDD] rounded-[4px] h-10 p-1;
}
.label_input{
  @apply text-[#333333] font-semibold text-[15px];
}
.card{
  @apply shadow-md shadow-slate-500/30;

}
.title{
  @apply text-xl font-bold ;

}
*{
  @apply text-sm;
}

.menu-list li{
  @apply hover:text-orange-500 text-black-1 cursor-pointer ease-in duration-75 font-medium text-base;
  font-family: IBMSans;
}

.status-confirmed{
  @apply bg-green-600/10 text-green-700 flex gap-3 py-2 px-4 rounded-full w-[120px] items-center;
}
.status-confirmed div{
  @apply bg-green-500;
}


.status-refused{
  @apply bg-red-600/10 text-red-500 flex gap-3 py-2 px-4 rounded-full w-[120px] items-center;
}
.status-refused div{
  @apply bg-red-500;
}
.status-pending{
  @apply bg-yellow-300/20 text-orange-400 flex gap-3 py-2 px-4 rounded-full w-[120px] items-center;
}
.status-pending div{
  @apply bg-orange-400;
}
.btn-gray{
  @apply bg-slate-200 text-gray-500 px-6 py-2 focus:outline rounded-md text-sm font-normal;
}
/* .info tr:first-child{
   @apply w-1/2;
}
.info tr:last-child{
  @apply w-1/2;
} */
.info tr td:first-child{
  @apply w-1/4;
}

.animated {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}


