@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.infinite-slide {
  display: flex;
  animation: slide 20s linear infinite;
  width: 200%; /* Double the width to accommodate the duplicated items */
  align-items: center;
}

.carousel-container {
  display: flex;
  overflow: hidden;
  width: 90%;
  padding: 20px 0;
}
.carousel-container::before{
  background-color: white;
  content: '';
}
.carousel-container::after {
  background-color: linear-gradient(to right,rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
  content: '';
  width: 100px;
  height: 100%;
  position: absolute;
  z-index: -2;
  right: 0;
  top: 0;
}

.carousel-item {
  flex: 0 0 25%; /* Show 5 items at once (100% / 5 = 20%) */
  box-sizing: border-box;
  padding: 10px;
  transition: transform 0.5s ease;
}

.carousel-item:hover {
  transform: scale(1.05);
}

.carousel-container:hover .infinite-slide {
  animation-play-state: paused;
}

.carousel-item h2 {
  font-size: 1.5em;
  margin: 0;
  padding: 10px 0;
}

.carousel-item p {
  font-size: 1em;
  margin: 0;
  padding: 10px 0;
}

.carousel-item {
  background: #fff;
  border-radius: 10px;
  box-shadow: none;
  text-align: center;
}