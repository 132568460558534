@tailwind base;
@tailwind components;
@tailwind utilities;


/* global */
:root{
    --primary-color:#1e293b;
    --yellow-color:#fddd59;
}

p{
    @apply text-slate-500 text-base;
}

body{
    @apply overflow-x-hidden ;
}

button{
    padding: 6px 10px;
    border-radius: 4px;
    font-weight: 400;
}


/* landing page - introdution */
.main-card{
    width: 100%;
    height: 500px;  
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius:6px;
}

.main-card-image{
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    height: 100%;
    background: url(../assets/reunion.jpg);
    background-size: cover;
    background-position: center;
   
}

.main-card-mask{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    z-index: 3;
}

.main-card-text{
    position: absolute;
    z-index:4;
    max-width: 80%;
    text-align: center;
    color: white !important;
}

.main-card-text > h3{
    color: white;
    margin-bottom: 20px;
}
.main-card-text > p{
    color: rgb(240, 239, 239);
    margin-bottom: 20px;
}

.main-card-button {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    width: 100%;
    text-transform: capitalize;
}



.main-card-button > .login{
    background-color: #fddc59;
    color: var(--primary-color);
}
.main-card-button > .signup{
    background-color: transparent;
    border: 1.5px solid #fddc59;
    color: white;
}

/* landing page - why */




